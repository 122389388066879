import { App } from '../core/app.js';
import { Dom } from '../core/dom.js';
import { IView, ViewState } from '../core/views.js'
import { VDom } from '../core/vdom.js';
import { Api } from '../data/api.js';
import { PlanList, PlanListItem } from '../data/models.js';

export class ListView implements IView {

    app: App;
    dm: Dom;
    state: ViewState;
    vd: VDom;
    api: Api;
    uid: string;
    list: PlanList;
    title: string = "";
    taskInputElm: HTMLInputElement;

    items: PlanListItem[];
    newItemTitle: string = "";

    init(params) {
        this.uid = params.uid;
    }

    start() {
    }

    resize(w?: number, h?: number) {
    }

    async ready() {
        this.list = await this.app.store.getPlanList(this.uid);
        this.title = "Ingen liste";
        if (this.list) {
            this.title = this.list.title;
            let items = await this.app.store.getPlanListItems(this.uid);
            if (items.length === 0) {
                //this.app.router.navigate("createlist");
                //return;
            }
            this.items = items;
            this.sortItems();
        }

        this.taskInputElm = document.getElementById("taskinput") as HTMLInputElement;
        //this.taskInputElm.focus();
        await this.app.apiSync.syncPlanListItems(this.uid);
        this.vd.init();
    }

    async addItem() {

        let sort = this.items.length > 0 ? this.items[0].sort - 1 : 0;

        let item: PlanListItem = {
            id: 0,
            uId: this.app.store.getUniqueId(),
            listUId: this.list.uId,
            title: this.newItemTitle,
            group: "",
            groupUId: "",
            tags: "",
            sort: sort,
            synced: false,
            isDone: false
        };

        this.newItemTitle = "";

        let res = await this.app.store.savePlanListItem(item);
        if (res) {
            this.items.unshift(item);
            this.vd.update();
            this.taskInputElm.focus();
            await this.app.apiSync.syncPlanListItem(item);
        }
        this.taskInputElm.focus();
    }

    end() {
    }

    async itemChecked(item, vElm, vAttrProp) {
        vElm.vNode.update();
        await this.app.apiSync.syncPlanListItem(item);
    }

    async msg(key, val) {
        if (key === "planlistitems_insert") {
            for (let l of (val as PlanListItem[])) {
                this.items.push(l);
            }
            this.sortItems();
            if(this.vd)
                this.vd.update();
        }
        if (key === "planlistitems_update" && val) {
            for (let ul of (val as PlanListItem[])) {
                for (let l of this.items) {
                    if (l.uId === ul.uId) {
                        this.app.apiSync.copyPlanListItem(ul, l);
                    }
                }
            }
            this.sortItems();
            if(this.vd)
                this.vd.update();
        }
    }

    private sortItems() {
        this.items = this.items.sort((a, b) => { return (a.sort < b.sort ? -1 : 0); });
    }
}