import { App } from "../core/app.js";
import { Database } from "../core/database.js";
import { AUser, PlanList, PlanListItem } from "./models.js";

export class Store {

    private app: App;
    private storeReadyCallback: (ok: boolean) => void;
    private storeReadyContext: any;

    private db: Database;

    private uniqueIds: Set<string>;

    constructor(app: App) {
        this.uniqueIds = new Set<string>;
        this.app = app;
    }

    ready(): boolean {
        if (this.db)
            return true;
        return false;
    }

    close() {
        this.db = null;
    }

    async init(storeReadyCallback: (ok: boolean) => void, storeReadyContext: any) {

        this.storeReadyCallback = storeReadyCallback;
        this.storeReadyContext = storeReadyContext;

        let ok = this.ready();

        if (!this.ready() && this.app.auth.userId) {
            ok = await this.initDatabase();
        }

        if (this.storeReadyCallback) {
            this.storeReadyCallback.call(this.storeReadyContext, ok);
        }
    }

    private async initDatabase(): Promise<boolean> {
        const p = new Promise<boolean>((res, rej) => {

            this.db = new Database(
                "planlist_" + this.app.auth.userId, 1,
                [
                    { name: "user", key: "uId", indexes: ["id"] },
                    { name: "planlist", key: "uId", indexes: ["id"] },
                    { name: "planlistitem", key: "uId", indexes: ["id", "listuid"] }
                ],
                function (ok: boolean) {
                    res(ok);
                }, this);    
        });

        return p;
    }

    getUniqueId(): string {

        let count = 0;
        let uid = "";
        do {
            uid = Date.now().toString(36) + Math.random().toString(36).substring(2, 5) + count;
            count++;
        } while (this.uniqueIds.has(uid))

        this.uniqueIds.add(uid);
        return uid;

    }

    async addUser(user: AUser): Promise<boolean> {
        const p = new Promise<boolean>((res, rej) => {
            this.db.update("user", user, function (d, o, e) {
                if (d && d.length) {
                    //user.dbid = d[0].dbid;
                }
                res(o);
            }, this);
        });
        return p;
    }

    async getUser(id: number) : Promise<AUser> {
        const p = new Promise<AUser>((res, rej) => {
            this.db.query("user", "id==" + id, function (d, o, e) {
                const user = (o && d && d.length) ? (d[0] as AUser) : null;
                res(user);
            }, this);
        });
        return p;
    }

    async getPlanList(uId: string): Promise<PlanList> {
        const p = new Promise<PlanList>((res, rej) => {
            this.db.query("planlist", "uId=='" + uId + "'", function (d, o, e) {
                const planlist = (o && d && d.length) ? (d[0] as PlanList) : null;
                res(planlist);
            }, this);
        });
        return p;
    }

    async getPlanLists(): Promise<PlanList[]> {
        const p = new Promise<PlanList[]>((res, rej) => {
            this.db.getAll("planlist", function (d, o, e) {
                if (o && d && d.length) {
                    res(d as PlanList[]);
                }
                else {
                    res([]);
                }
            }, this);
        });
        return p;
    }

    async savePlanList(planlist: PlanList): Promise<boolean> {
        const p = new Promise<boolean>((res, rej) => {
            this.db.update("planlist", planlist, function (d, o, e) {
                if (o && d && d.length) {
                    res(true);
                }
                else {
                    res(false);
                }
            }, this);
        });
        return p;
    }

    async getPlanListItems(planListUId: string): Promise<PlanListItem[]> {
        const p = new Promise<PlanListItem[]>((res, rej) => {
            this.db.query("planlistitem", "listUId=='" + planListUId + "'", function (d, o, e) {
                if (o && d && d.length) {
                    let items = d as PlanListItem[];
                    res(items);
                }
                else {
                    res([]);
                }
            }, this);
        });
        return p;
    }

    async savePlanListItem(planlistitem: PlanListItem): Promise<boolean> {
        const p = new Promise<boolean>((res, rej) => {
            this.db.update("planlistitem", planlistitem, function (d, o, e) {
                if (o && d && d.length) {
                    res(true);
                }
                else {
                    res(false);
                }
            }, this);
        });
        return p;
    }
}