import { App } from '../core/app.js';
import { Dom } from '../core/dom.js';
import { IView, ViewState } from '../core/views.js'
import { VDom } from '../core/vdom.js';
import { Api } from '../data/api.js';

export class LogoutView implements IView {

    app: App;
    dm: Dom;
    state: ViewState;
    vd: VDom;
    api: Api;
    isPublic = true;

    init() {
    }

    start() {
    }

    resize(w?: number, h?: number) {
    }

    ready() {
        this.app.auth.logout();
        this.app.router.login();
    }

    end() {
    }

}