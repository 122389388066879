import { AUser } from "../data/models.js";
import { App } from "./app.js";

export class Auth {

    app: App;
    user: AUser;
    sessionId: string;
    userId: number;

    constructor(app: App) {
        this.app = app;
    }

    private getSessionId(): string {
        return window.localStorage.getItem("sessionId");
    }

    private getUserId(): number {
        let userId = window.localStorage.getItem("userId");
        if (userId) {
            return parseInt(userId, 10);
        }
        return 0;
    }

    logout() {
        this.user = null;
        this.userId = 0;
        this.sessionId = null;
        window.localStorage.removeItem("sessionId");
        window.localStorage.removeItem("userId");
        this.app.store.close();
    }

    hasSessionId(): boolean {
        if (this.sessionId)
            return true;
        return false;
    }

    setUser(user: AUser) {
        this.user = user;
        this.sessionId = user.sessionId;
        this.userId = user.id;
        window.localStorage.setItem("sessionId", user.sessionId);
        window.localStorage.setItem("userId", "" + user.id);
    }

    hasUserAndSessionIds(): boolean {
        this.sessionId = this.getSessionId();
        this.userId = this.getUserId();
        if (this.getSessionId() && this.getUserId())
            return true;
        return false;
    }

    async check(): Promise<boolean> {
        if (!this.hasUserAndSessionIds()) {
            return false;
        }
        else {

            if (!this.app.store.ready())
                return false;

            if (!this.user) {

                let self = this;
                let user = await this.app.store.getUser(this.userId);
                if (user) {
                    self.user = user;
                    return true;
                }
                else {
                    return false;
                }
            }
        }

        return true;
    }

    //private async getUserFromDb(): Promise<AUser> {
    //    const p = new Promise<AUser>((res, rej) => {
    //        this.app.store.getUser(this.userId).then(
    //            function (value) {
    //                console.log(value);
    //                res(value);
    //            }
    //        );
    //    });
    //    return p;
    //}

}