import { App } from '../core/app.js';
import { Dom } from '../core/dom.js';
import { IView, ViewState } from '../core/views.js'
import { VDom } from '../core/vdom.js';
import { PlanList } from '../data/models.js';

export class MainView implements IView {

    id = "main";
    app: App;
    dm: Dom;
    state: ViewState;
    vd: VDom;

    player = "";
    lists: PlanList[];

    init() {        
    }

    start() {
    }

    resize(w?: number, h?: number) {
    }

    async ready() {
        this.lists = await this.app.store.getPlanLists()
        if (this.lists.length === 0) {
            //this.app.router.navigate("createlist");
            //return;
        }

        await this.app.apiSync.syncPlanLists();

        this.player = this.app.auth.user.name;

        this.vd.init();
    }

    async msg(key, val) {
        if (key === "planlists_insert") {
            for (let l of (val as PlanList[])) {
                this.lists.push(l);
            }
            this.vd.update();
        }
        if (key === "planlists_update" && val) {
            for (let ul of (val as PlanList[])) {
                for (let l of this.lists) {
                    if (l.uId === ul.uId) {
                        this.app.apiSync.copyPlanList(ul, l);
                    }
                }
            }
            this.vd.update();
        }
    }

    end() {
    }

    nav() {
        this.app.router.navigate("login");
    }


}