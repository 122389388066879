import { App } from '../core/app.js';
import { Dom } from '../core/dom.js';
import { IView, ViewState } from '../core/views.js'
import { VDom } from '../core/vdom.js';
import { Api } from '../data/api.js';
import { PlanList } from '../data/models.js';

export class CreateListView implements IView {

    id = "createlist";
    app: App;
    dm: Dom;
    state: ViewState;
    vd: VDom;
    api: Api;

    listname = "";

    init() {
    }

    start() {
    }

    resize(w?: number, h?: number) {
    }

    ready() {
        const listnameElm = document.getElementById("listname");
        listnameElm.focus();
        this.vd.init();
    }

    end() {
    }

    async createList() {

        var sl: PlanList = {
            id: 0,
            uId: this.app.store.getUniqueId(),
            title: this.listname,
            description: "",
            synced: false
        }
        var res = await this.app.store.savePlanList(sl);
        if (res) {
            this.app.apiSync.syncPlanList(sl);
            this.app.router.navigate("main");
        }
    }

}