import { App } from "../core/app.js";
import { IEntity } from "../core/database.js";
import { AUser, PlanList, PlanListItem } from "./models.js";

export interface ApiResult<T> {
    ok: boolean;
    data: T;
}

export class Api {

    private baseUrl = "/api";

    user: UserApi;
    planlist: PlanListApi;
    planlistitem: PlanListItemApi;

    constructor(app: App) {
        this.user = new UserApi(app, this.baseUrl);
        this.planlist = new PlanListApi(app, this.baseUrl);
        this.planlistitem = new PlanListItemApi(app, this.baseUrl);
    }
}

class BaseApi {

    private baseUrl: string;
    protected app: App;

    constructor(app: App, baseUrl: string) {
        this.app = app;
        this.baseUrl = baseUrl;
    }

    protected async apiGet<T>(url: string): Promise<ApiResult<T>> {
        const response = await fetch(this.baseUrl + url);
        const ok = response.ok;
        const text = await response.text();
        var res: ApiResult<T> = {
            ok: ok,
            data: ok && text.length ? JSON.parse(text) : null,
        };
        return res;
    }

    protected async apiPost<T>(url: string, data: any): Promise<ApiResult<T>> {

        const response = await fetch(this.baseUrl + url, {
            method: "POST",
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data)
        });
        const ok = response.ok;
        const text = await response.text();
        var res: ApiResult<T> = {
            ok: ok,
            data: ok && text.length ? JSON.parse(text) : null,
        };
        return res;
    }

}

class UserApi extends BaseApi {

    async login(username: string, password: string): Promise<ApiResult<AUser>> {
        const url = "/user/login/?username=" + username + "&password=" + password;
        return await this.apiGet<AUser>(url);
    }
}

class PlanListApi extends BaseApi {

    async getlists(): Promise<ApiResult<PlanList[]>> {
        const url = "/planlist/getlists?sid=" + this.app.auth.sessionId;
        return await this.apiGet(url);
    }

    async store(planlist: PlanList): Promise<ApiResult<PlanList>> {
        const url = "/planlist/store/?sid=" + this.app.auth.sessionId;
        return await this.apiPost<PlanList>(url, planlist);
    }

}

class PlanListItemApi extends BaseApi {

    async getitems(listUId: string): Promise<ApiResult<PlanListItem[]>> {
        const url = "/planlistitem/getitems?sid=" + this.app.auth.sessionId + "&listuid=" + listUId;
        return await this.apiGet(url);
    }

    async store(planlistitem: PlanListItem): Promise<ApiResult<PlanListItem>> {
        const url = "/planlistitem/store/?sid=" + this.app.auth.sessionId;
        return await this.apiPost<PlanListItem>(url, planlistitem);
    }

}
