import { App } from "./app.js";
import { IView } from "./views.js";

export class Msg {

    private app: App;

    constructor(app: App) {
        this.app = app;
    }

    broadcast(key: string, val?: any) {

        let cvs = this.app.currViews
        for (let vkey in cvs) {
            let view = cvs[vkey];
            if (view && typeof (view.msg) !== "undefined") {
                view.msg(key, val);
            }
        }

    }
}