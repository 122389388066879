import { App } from "../core/app.js";
import { Api } from "./api.js";
import { PlanList, PlanListItem } from "./models.js";
import { Store } from "./store.js";


export class ApiSync {

    private app: App;
    private api: Api;
    private store: Store;

    constructor(app: App) {
        this.app = app;
        this.api = app.api;
        this.store = app.store;
    }

    async syncPlanLists() {
        var remotesRes = await this.api.planlist.getlists();

        if (!remotesRes.ok)
            return;

        var remotes = remotesRes.data;
        var locals = await this.store.getPlanLists();

        // INSERT NEW
        let insertedPlanLists: PlanList[] = [];
        for (let rl of remotes) {
            let localExists = false;
            for (let ll of locals) {
                if (rl.uId === ll.uId) {
                    localExists = true;
                    break;
                }
            }
            if (!localExists) {
                rl.synced = true;
                await this.store.savePlanList(rl);
                insertedPlanLists.push(rl);
            }
        }

        // DATA CHANGES
        let changedPlanLists: PlanList[] = [];
        for (let rl of remotes) {
            let localExists = false;
            for (let ll of locals) {
                if (rl.uId === ll.uId) {
                    if (rl.title !== ll.title || rl.description !== ll.description || rl.id !== ll.id || !ll.synced) {
                        ll.title = rl.title;
                        ll.description = rl.description;
                        ll.id = rl.id;
                        ll.synced = true;
                        this.store.savePlanList(ll);
                        await this.store.savePlanList(ll);
                        changedPlanLists.push(ll);
                    }
                    break;
                }
            }
        }

        // SAVE LOCAL TO REMOTE
        for (let ll of locals) {
            if (ll.id === 0) {
                let storedList = await this.api.planlist.store(ll);
                if (storedList.ok && storedList.data) {
                    ll.id = storedList.data.id;
                    ll.synced = true;
                    if(await this.store.savePlanList(ll))
                        changedPlanLists.push(ll);
                }
            }
        }

        // TODO: DELETED

        if (changedPlanLists.length > 0) {
            this.broadcast("planlists_update", changedPlanLists);
        }

        if (insertedPlanLists.length > 0) {
            this.broadcast("planlists_insert", insertedPlanLists);
        }

    }

    async syncPlanList(planlist: PlanList) {
        let res = await this.app.api.planlist.store(planlist);
        if (res.ok && res.data) {
            planlist.id = res.data.id;
            planlist.synced = true;
            if(await this.store.savePlanList(planlist))
                this.broadcast("planlists_update", [planlist]);
        }
    }

    copyPlanList(ul: PlanList, l: PlanList) {
        l.description = ul.description;
        l.id = ul.id;
        l.synced = ul.synced;
        l.title = ul.title;
        l.uId = ul.uId;
    }


    async syncPlanListItems(listUId: string) {
        var remotesRes = await this.api.planlistitem.getitems(listUId);

        if (!remotesRes.ok)
            return;

        var remotes = remotesRes.data;
        var locals = await this.store.getPlanListItems(listUId);

        // INSERT NEW
        let insertedPlanListItems: PlanListItem[] = [];
        for (let rl of remotes) {
            let localExists = false;
            for (let ll of locals) {
                if (rl.uId === ll.uId) {
                    localExists = true;
                    break;
                }
            }
            if (!localExists) {
                rl.synced = true;
                await this.store.savePlanListItem(rl);
                insertedPlanListItems.push(rl);
            }
        }

        // DATA CHANGES
        let changedPlanListItems: PlanListItem[] = [];
        for (let rl of remotes) {
            let localExists = false;
            for (let ll of locals) {
                if (rl.uId === ll.uId) {
                    if (
                        rl.title !== ll.title ||
                        rl.isDone !== ll.isDone ||
                        rl.groupUId !== ll.groupUId ||
                        rl.tags !== ll.tags ||
                        rl.id !== ll.id ||
                        !ll.synced
                    ) {
                        ll.title = rl.title;
                        ll.isDone = rl.isDone;
                        ll.groupUId = rl.groupUId;
                        ll.tags = rl.tags;
                        ll.id = rl.id;
                        ll.listUId = rl.listUId;
                        ll.synced = true;
                        this.store.savePlanListItem(ll);
                        await this.store.savePlanListItem(ll);
                        changedPlanListItems.push(ll);
                    }
                    break;
                }
            }
        }

        // SAVE LOCAL TO REMOTE
        for (let ll of locals) {
            if (ll.id === 0) {
                let storedList = await this.api.planlistitem.store(ll);
                if (storedList.ok && storedList.data) {
                    ll.id = storedList.data.id;
                    ll.synced = true;
                    if (await this.store.savePlanListItem(ll))
                        changedPlanListItems.push(ll);
                }
            }
        }

        // TODO: DELETED

        if (changedPlanListItems.length > 0) {
            this.broadcast("planlistitems_update", changedPlanListItems);
        }

        if (insertedPlanListItems.length > 0) {
            this.broadcast("planlistitems_insert", insertedPlanListItems);
        }

    }

    async syncPlanListItem(planlistitem: PlanListItem) {
        let res = await this.app.api.planlistitem.store(planlistitem);
        if (res.ok && res.data) {
            planlistitem.id = res.data.id;
            planlistitem.synced = true;
            if (await this.store.savePlanListItem(planlistitem))
                this.broadcast("planlistitems_update", [planlistitem]);
        }
    }

    copyPlanListItem(uli: PlanListItem, li: PlanListItem) {
        li.id = uli.id;
        li.synced = uli.synced;
        li.title = uli.title;
        li.uId = uli.uId;
        li.group = uli.group;
        li.groupUId = uli.groupUId;
        li.listUId = uli.listUId;
        li.tags = uli.tags;
    }

    private broadcast(key: string, val?: any) {
        this.app.msg.broadcast(key, val);
    }

}