import { App } from '../core/app.js';
import { Dom } from '../core/dom.js';
import { IView, ViewState } from '../core/views.js'
import { VDom } from '../core/vdom.js';
import { Api } from '../data/api.js';

export class BootView implements IView {

    app: App;
    dm: Dom;
    state: ViewState;
    vd: VDom;
    api: Api;

    init() {
        this.storeReady(true);
    }

    start() {
    }

    resize(w?: number, h?: number) {
    }

    ready() {
    }

    end() {
    }

    storeReady(ok: boolean) {

        const startView = this.app.auth.hasSessionId() ? "main" : "login";

        this.app.router.navigate(startView);
    }

}