import { ViewManager } from "./views.js";

export class Router {

    private homeview: string;
    private viewman: ViewManager;

    constructor(homeview: string, viewman: ViewManager) {

        this.homeview = homeview;
        this.viewman = viewman;

        let self = this;

        //window.addEventListener('popstate', function () {
        //    const path = window.location.pathname;
        //    console.log("popstate", path);
        //    self.navigate(path);
        //});

        //window.addEventListener('DOMContentLoaded', function () {
        //    const hash = "/" + window.location.hash.substring(1);
        //    self.donav(hash);
        //});

        window.addEventListener('hashchange', () => {
            const hash = "/" + window.location.hash.substring(1);
            self.donav(hash);
        });

    }

    run() {
        const hash = "/" + window.location.hash.substring(1);
        this.donav(hash);
    }

    login() {
        window.location.hash = '#login';
    }

    navigate(view: string) {
        window.location.hash = '#' + view;     
        //history.pushState(null, null, '#' + view);
    }

    private donav(view: string) {
        if (view.length === 0)
            return;
        let paths = view.substring(1).split("?");
        let path = paths[0];
        let params = paths.length > 1 ? paths[1] : "";
        if (path === "")
            path = this.homeview;
        this.viewman.navigate(path, params);
    }

}