import { App } from "./core/app.js";
import { Auth } from "./core/auth.js";
import { Database } from "./core/database.js";
import { Msg } from "./core/msg.js";
import { Router } from "./core/router.js";
import { IView, Views } from "./core/views.js";
import { Api } from "./data/api.js";
import { ApiSync } from "./data/apisync.js";
import { Store } from "./data/store.js";
import { BootView } from "./views/bootview.js";
import { CreateListView } from "./views/createlistview.js";
import { ListView } from "./views/listview.js";
import { LoginView } from "./views/loginview.js";
import { LogoutView } from "./views/logoutview.js";
import { MainView } from "./views/mainview.js";

export default class Main {

    app: App;
    views: Views;

    constructor() {
        this.app = new App();
        this.app.hostElm = document.getElementById("content");
        this.app.auth = new Auth(this.app);
        this.app.store = new Store(this.app);
        this.app.api = new Api(this.app);
        this.app.apiSync = new ApiSync(this.app);
        this.app.msg = new Msg(this.app);
        this.app.currViews = {};
        this.views = new Views(this.app);

        const contentViewMan = this.views.getViewManager("content");
        contentViewMan.add("boot", BootView, "views/boot");
        contentViewMan.add("main", MainView, "views/main");
        contentViewMan.add("login", LoginView, "views/login");
        contentViewMan.add("createlist", CreateListView, "views/createlist");
        contentViewMan.add("list", ListView, "views/list");
        contentViewMan.add("logout", LogoutView);

        let router = new Router("main", contentViewMan);
        this.app.router = router;

        this.app.start();

    }

}
const main = new Main();