import { Api } from '../data/api.js';
import { ApiSync } from '../data/apisync.js';
import { Store } from '../data/store.js';
import { Auth } from './auth.js';
import { Database } from './database.js';
import { Dom } from './dom.js'
import { Msg } from './msg.js';
import { Router } from './router.js';
import { IView, ViewManager } from './views.js';

export class App {

    hostElm: Element;
    nsInst: any;
    appID: string;
    dm: Dom;
    router: Router;
    auth: Auth;
    db: Database;
    store: Store;
    api: Api;
    apiSync: ApiSync;
    msg: Msg;
    currViews: { [key: string]: IView };
    //alpine: any;
    //vw: BitsTale.Views;

    start() {
        if (!this.auth.hasUserAndSessionIds()) {
            this.router.login();
        }
        else {
            this.store.init(this.storeReady, this);
        }
    }

    private async storeReady(ok: boolean) {

        const authCheck = await this.auth.check();

        if (authCheck && ok)
            this.router.run();
        else
            this.router.navigate("login");
    }

}