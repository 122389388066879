import { App } from '../core/app.js';
import { Dom } from '../core/dom.js';
import { IView, ViewState } from '../core/views.js'
import { VDom } from '../core/vdom.js';
import { Api } from '../data/api.js';

export class LoginView implements IView {

    app: App;
    dm: Dom;
    state: ViewState;
    vd: VDom;
    api: Api;
    isPublic = true;

    username = "";
    password = "";
    classList = "fw-bold";
    isLoginError = false;

    init() {
    }

    start() {
    }

    resize(w?: number, h?: number) {
    }

    ready() {
        this.api = this.app.api;
        this.vd.init();

    }

    async login() {
        if (this.isLoginError) {
            this.isLoginError = false;
            this.vd.update();
        }
        var res = await this.api.user.login(this.username, this.password);
        if (!res.ok || !res.data) {
            this.isLoginError = true;
            this.vd.update();
            return;
        }

        const auser = res.data;
        auser.uId = this.app.store.getUniqueId();
        this.app.auth.setUser(auser);

        this.app.store.init(this.storeReady, this);
    }

    async storeReady(ok: boolean) {

        if (ok) {
            const sok = await this.app.store.addUser(this.app.auth.user);
            if (sok) {
                this.app.router.navigate("main");
            }
        }

    }

    end() {
    }

}