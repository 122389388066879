import { App } from './app.js'
import { Dom } from './dom.js'
import { VDom } from './vdom.js';

export interface IView {
    id?: string;
    app: App;
    dm: Dom;
    state: ViewState;
    vd: VDom;
    init(params?: {});
    start();
    resize(w?: number, h?: number);
    ready();
    end();
    msg?: (key: string, val?: any) => void;
    isPublic?: boolean;
}

export class ViewState {
    persistView: boolean;
    domReady: boolean;
    width: number;
    height: number;
}

interface IRegView {
    path: string;
    viewClass: any;
    viewHtmlPath: string;
}

export class ViewManager {

    private domId: string;
    private hostElm: any;
    private dataId: string;
    private app: App;
    private regViews: { [name: string]: IRegView };

    constructor(domId: string, app: App) {
        this.domId = domId;
        var elm = document.getElementById(domId);
        if (!elm)
            return;
        this.app = app;
        this.hostElm = elm;
    }

    add(path: string, viewClass: any, viewHtmlPath: string = null) {
        if (!this.regViews)
            this.regViews = {};

        const lcPath = path.toLowerCase();
        this.regViews[lcPath] = {
            path: lcPath,
            viewClass: viewClass,
            viewHtmlPath: viewHtmlPath
        };

    }

    navigate(path: string, params: string = null) {
        var regView = this.regViews[path.toLowerCase()];
        if (!regView)
            return;

        const self = this;
        const view = new regView.viewClass() as IView;
        if (!view)
            return;

        if (!view.isPublic && !this.app.auth.hasUserAndSessionIds()) {
            this.app.auth.logout();
            this.app.router.login();
            return;
        }

        this.app.currViews[this.domId] = view;
        view.app = this.app;
        view.init(this.buildParams(params));

        if (!regView.viewHtmlPath) {
            this.startView(view, false);
            return;
        }

        this.loadView(regView.viewHtmlPath, function (html) {
            self.startView(view, true, html);
        }, this);
    }

    private buildParams(params: string): {} {
        let p = {};
        if (params) {
            let ps = params.split("&");
            for (let i = 0; i < ps.length; i++) {
                let kv = ps[i].split("=");
                if (kv.length === 2) {
                    p[kv[0].trim()] = kv[1].trim();
                }
            }
        }
        return p;
    }

    private startView(view: IView, requireHtml: boolean, html: string = null) {
        let currView = this.app.currViews[this.domId];
        if (currView && currView !== view)
            return;
        if (requireHtml) {
            if (html === null) {
                console.log("Loading HTML error");
                return;
            }

            this.hostElm.innerHTML = html;
            if (!view.vd)
                view.vd = new VDom(view.app.hostElm as any, view);
        }
        view.ready();
    }

    private loadView(htmlPath: string, callback: (html: string) => void = null, context: any = null): void {
        let xhr = new XMLHttpRequest();
        let path = htmlPath.toLowerCase() + '.html?_=' + new Date().getTime();
        xhr.open('GET', path);
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (callback) {
                    if (context)
                        callback.call(context, xhr.responseText);
                    else
                        callback(null);
                }
            }
            else {
                console.log('Request failed.  Returned status of ' + xhr.status);
            }
        };
        xhr.send();
    }

}

export class Views {

    private viewManagers: ViewManager[];

    getViewManager(domSelector: string): ViewManager {
        const manager = new ViewManager(domSelector, this.app);
        this.viewManagers.push(manager);
        return manager;
    }

    view: IView;
    viewKey: string;
    views: { [name: string]: IView } = {};
    app: App;

    viewWidth: number;
    viewHeight: number;

    constructor(app: App) {
        this.app = app;
        this.viewManagers = [];
        let self = this;
        window.addEventListener("resize", function () {
            self.updateViewDim();
        });
        this.updateViewDim();
    }

    private updateViewDim() {
        let bw = document.body.scrollWidth;
        this.viewWidth = Math.min(bw, window.innerWidth);
        this.viewHeight = window.innerHeight;
        this.notifyViewResize(this.view);
    }

    private notifyViewResize(view: IView) {
        if (view && view.state.domReady) {
            if (view.state.width !== this.viewWidth || view.state.height !== this.viewHeight) {
                view.state.width = this.viewWidth;
                view.state.height = this.viewHeight;
                view.resize(this.viewWidth, this.viewHeight);
            }
        }
    }
}
