const VDOM_ATTR = "vd";
const VDOM_FORATTR = "vd-for";

export class VDom {

    private dom: HTMLDivElement;
    private data: any;
    private vNode: VNode;

    constructor(hostElm: HTMLDivElement, data: any) {
        this.dom = hostElm;
        this.data = data;
    }

    init() {
        this.vNode = new VNode(this.dom, this.data, this.data);
        this.update();
        this.removeCloak();
    }

    removeCloak() {
        let vdCloaks = document.querySelectorAll(".vd-cloak");
        for (let i = 0; i < vdCloaks.length; i++) {
            vdCloaks[i].classList.remove("vd-cloak");
        }
    }

    update() {
        this.vNode.update();
    }

}


class VNode {

    elm: HTMLElement;
    data: any;
    parentData: any;
    vFors: VFor[];
    vElms: VElm[];
    createTemplate: boolean;

    constructor(elm: HTMLElement, data: any, parentData: any) {
        this.elm = elm;
        this.data = data;
        this.parentData = parentData;
        this.vFors = [];
        this.vElms = [];
        this.findVs(elm);
        this.update();
    }

    private findVs(elm: HTMLElement) {
        // First VFors
        let forElms = elm.querySelectorAll("[" + VDOM_FORATTR + "]");

        let forID = 0;
        for (let i = 0; i < forElms.length; i++) {
            let forElm = forElms[i] as HTMLElement;
            if (forElm === elm)
                continue; // Self

            // find parent
            let parentElm = forElm.parentElement;
            let firstLevel = true;
            while (parentElm !== elm) {

                if (parentElm.getAttribute(VDOM_FORATTR)) {
                    // Not first level
                    firstLevel = false;
                    break;
                }
                parentElm = parentElm.parentElement;
            }

            if (!firstLevel)
                continue;

            let vFor = new VFor(forElm, this.data);
            forID++;
            this.vFors.push(vFor);
        }

        for (let i = 0; i < this.vFors.length; i++) {
            this.vFors[i].initTemplate();
        }

        let elms = elm.querySelectorAll("[" + VDOM_ATTR + "]");
        for (let i = 0; i < elms.length; i++) {
            let elm = elms[i];
            let vdAttrVal = elm.getAttribute(VDOM_ATTR);
            let vElm = new VElm(elm, vdAttrVal, this.data, this.parentData, this);
            this.vElms.push(vElm);
            elm.removeAttribute(VDOM_ATTR);
        }

    }

    update() {
        if (this.vFors)
            for (let i = 0; i < this.vFors.length; i++) {
                this.vFors[i].data = this.data;
                this.vFors[i].update();
            }
        if (this.vElms)
            for (let i = 0; i < this.vElms.length; i++) {
                this.vElms[i].data = this.data;
                this.vElms[i].update();
            }
    }

}

class VElm {

    elm: Element;
    vdAttrVal: string;
    data: any;
    parentData: any;
    props: VAttrProp[];
    vNode: VNode;

    constructor(elm: Element, vdAttrVal: string, data: any, parentData: any, vNode: VNode) {
        this.elm = elm;
        this.vdAttrVal = vdAttrVal;
        this.data = data;
        this.parentData = parentData;
        this.vNode = vNode;
        this.init();
    }

    private init() {
        this.props = [];
        if (this.vdAttrVal) {
            let ps = this.vdAttrVal.split(/,(?=(?:[^']*'[^']*')*[^']*$)/);
            for (let p of ps) {
                let vp = p.split(/:(?=(?:[^']*'[^']*')*[^']*$)/);
                if(vp.length === 3)
                    console.log("vp", vp);
                if (vp.length === 2) {
                    let domKey = vp[0].trim();
                    let dataKey = vp[1].trim();
                    if (domKey && dataKey) {
                        let prop = new VAttrProp(this, domKey, dataKey);
                        this.props.push(prop);
                    }
                }
            }
        }
        this.update();
    }

    update() {
        if (this.props) {
            for (let p of this.props) {
                p.update();
            }
        }
    }
}

class VFor {

    template: string;
    vNodes: VNode[];
    forElm: HTMLElement;
    forElmParent: HTMLElement;
    childIdx: number;

    data: any;
    dataKey: string;
    preDataKeys: string[];

    constructor(forElm: HTMLElement, data: any) {
        this.vNodes = [];
        this.forElm = forElm;
        this.forElmParent = forElm.parentElement;
        this.childIdx = this.getForChildIdx(forElm, this.forElmParent);
        this.data = data;
        this.dataKey = VFor.getAttrVal(forElm);
    }

    initTemplate() {
        this.forElm.removeAttribute(VDOM_FORATTR);
        this.template = this.forElm.outerHTML;
        this.forElm.outerHTML = "";
    }

    update() {

        // Create an VNode from template for every item in this.data array
        let data = this.data[this.dataKey];
        if (!(data && data.length)) {
            // TODO: remove any from HTML
            for (let i = 0; i < this.vNodes.length; i++) {
                let vn = this.vNodes[i];
                for (let j = 0; j < vn.vFors.length; j++) {
                    this.forElmParent.removeChild(vn.vFors[j].forElm);
                }
            }
            this.vNodes = [];
            return;
        }

        for (let i = 0; i < data.length; i++) {
            let dat = data[i];
            if (i < this.vNodes.length) {
                let vn = this.vNodes[i];
                vn.data = dat;
                vn.update();
            }
            else {
                let hostElm = document.createElement(this.forElmParent.localName);
                hostElm.innerHTML = this.template;
                let elm = hostElm.firstChild as HTMLElement;
                let childIdx = this.getCurrentChildIdx();
                this.forElmParent.insertBefore(elm, this.forElmParent.children[childIdx + i]);
                let vnode = new VNode(elm, data[i], this.data);
                this.vNodes.push(vnode);
            }
        }
        let removedVNodes = this.vNodes.splice(data.length);
        for (let i = 0; i < removedVNodes.length; i++) {
            let vn = removedVNodes[i];
            this.forElmParent.removeChild(vn.elm);
        }

    }

    private getCurrentChildIdx(): number {
        let idx = this.childIdx;
        for (let i = 0; i < this.preDataKeys.length; i++) {
            idx += this.data[this.preDataKeys[i]].length - 1;
        }
        return idx;
    }

    private getForChildIdx(forElm: HTMLElement, parentElm: HTMLElement) {
        this.preDataKeys = [];
        if (!parentElm)
            return 0;
        for (let i = 0; i < parentElm.children.length; i++) {
            let c = parentElm.children[i];
            if (c === forElm)
                return i;
            let attrVal = VFor.getAttrVal(c);
            if (attrVal) {
                this.preDataKeys.push(attrVal);
            }
        }
        return 0;
    }

    public static getAttrVal(elm: Element): string {
        let vdforattr = elm.getAttribute(VDOM_FORATTR);
        if (vdforattr) {
            return vdforattr;
        }
        return "";
    }

}

class VAttrProp {

    vElm: VElm;
    domKey: string;
    jsKey: string;
    dataKey: string;
    value: string;

    constructor(vElm: VElm, domKey: string, dataKey: string) {
        this.vElm = vElm;
        this.domKey = domKey;
        this.dataKey = dataKey;

        let self = this;
        switch (domKey) {
            case "value":
                this.jsKey = "value";
                this.vElm.elm.addEventListener("blur", function () {
                    self.updateDataValue();
                });
                break;
            case "class":
                this.jsKey = "className";
                if (this.vElm.elm.className) {
                    this.value = this.vElm.elm.className + " ";
                }
                else {
                    this.value = "";
                }
                break;
            case "classif":
                this.jsKey = "classif";
                break;
            case "text":
                this.jsKey = "innerText";
                break;
            case "html":
                this.jsKey = "innerHTML";
                break;
            case "click":
                this.jsKey = "";
                this.vElm.elm.addEventListener("click", function () {
                    self.vElm.data[self.dataKey].call(self.vElm.data, self.vElm, self);
                });
                break;
            case "checked":
                this.jsKey = "checked";
                let checkProps = self.dataKey.split("|");
                let checkProp = checkProps[0];
                if (this.vElm.data[checkProp]) {
                    (this.vElm.elm as any).checked = true;
                }
                this.vElm.elm.addEventListener("change", function () {
                    self.vElm.data[checkProp] = this.checked;
                    if (checkProps.length === 2 && checkProps[1] && self.vElm.parentData) {
                        self.vElm.parentData[checkProps[1]].call(self.vElm.parentData, self.vElm.data, self.vElm, self);
                    }
                });
                break;
            case "enter":
                this.jsKey = "";
                this.vElm.elm.addEventListener("keyup", function (ev: any) {
                    ev.preventDefault();
                    if (ev.keyCode === 13) {
                        let inputElm = self.vElm.elm as HTMLInputElement;
                        if (inputElm)
                            inputElm.blur();
                        self.vElm.data[self.dataKey].call(self.vElm.data, self.vElm, self);
                    }
                });
                break;
            case "nav":
                this.vElm.elm.addEventListener("click", function () {
                    //self.state.nav.to(self.dataKey);
                });
                break;
            case "submit":
                this.jsKey = "";
                this.vElm.elm.setAttribute("onsubmit", "return false;");
                this.vElm.elm.addEventListener("submit", function () {
                    let inputElm = document.activeElement as HTMLInputElement;
                    if (inputElm && inputElm.blur) {
                        inputElm.blur();
                        inputElm.focus();
                    }
                    self.vElm.data[self.dataKey].call(self.vElm.data, self.vElm, self);
                });
                break;
            //case "items":
            //    this.value = self.vElm.elm.innerHTML;
            //    self.vElm.elm.innerHTML = "";
            //    break;
            case "visible":
                this.jsKey = "1";
                break;
            case "attr":
                this.jsKey = "attr";
                break;
        }
    }

    private updateDataValue() {
        let inputElm = this.vElm.elm as HTMLInputElement;
        this.vElm.data[this.dataKey] = inputElm.value;
    }

    private updateDataItems() {

    }

    update() {
        if (this.jsKey) {
            let inputElm = this.vElm.elm as HTMLInputElement;
            switch (this.domKey) {
                case "checked":
                    let checkProps = this.dataKey.split("|");
                    (this.vElm.elm as any).checked = this.vElm.data[checkProps[0]];
                    break;
                case "classif":
                    let dk = this.dataKey;
                    let dks = dk.split("?");
                    if (dks.length === 2) {
                        this.vElm.elm.classList.toggle((dks[1].trim()), this.vElm.data[(dks[0].trim())]);
                    }
                    break;
                case "class":
                    inputElm[this.jsKey] = this.value + this.vElm.data[this.dataKey];
                    break;
                case "visible":
                    var visible = this.vElm.data[this.dataKey];
                    if (visible) {
                        this.vElm.elm.classList.remove("d-none");
                    }
                    else {
                        this.vElm.elm.classList.add("d-none");
                    }
                    break;
                case "attr":
                    let reAttr = /'([^']*)'|[^\s=\+]+/g;
                    var aps = this.dataKey.match(reAttr);
                    if (aps.length > 1) {
                        let attval = "";
                        for (let apsIdx = 1; apsIdx < aps.length; apsIdx++) {
                            let av = aps[apsIdx];
                            if (av !== "" && av[0] === "'")
                                attval += av.substring(1, Math.min(av.length,av.length - 1));
                            else
                                attval += this.vElm.data[av];
                        }
                        let attrName = aps[0];
                        let attrElm = (this.vElm.elm as any);
                        switch (attrName) {
                            case "text":
                                attrElm.innerText = attval;
                                break;
                            case "html":
                                attrElm.innerHTML = attval;
                            default:
                                attrElm.setAttribute(attrName, attval);
                        }
                    }
                    break;
                default:
                    inputElm[this.jsKey] = this.vElm.data[this.dataKey];
                    break;
            }
        }
    }

}
