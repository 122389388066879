export interface IEntity {
    uId?: string;
}

export interface IDataStoreConfig {
    name: string;
    key?: string;
    indexes?: string[];
}

type QueryOperator = "==" | "!=" | "<" | "<=" | ">" | ">=";

interface Query {
    property: string;
    operator: QueryOperator;
    value: any;
}

export class Database {
    private _dbname: string;
    private _version: number;
    private _storeConfigs: IDataStoreConfig[];

    constructor(
        dbname: string, version: number,
        stores: IDataStoreConfig[],
        callback: (IDBDatabase, ok: boolean, error?: Error) => void,
        context?: any
    ) {
        this._dbname = dbname;
        this._version = version;
        this._storeConfigs = stores;
        this.openDB(callback, context);
    }

    private openDB(callback: (IDBDatabase, ok?: boolean, error?: Error) => void, context?: any) {
        const openRequest = indexedDB.open(this._dbname, this._version);

        openRequest.onupgradeneeded = (event: Event) => {
            const db = (event.target as IDBOpenDBRequest).result;

            for (let i = 0; i < this._storeConfigs.length; i++) {
                let sc = this._storeConfigs[i];
                if (db.objectStoreNames.contains(sc.name)) {
                    // TODO: Do we need to do any upgrade on the existing data?
                    db.deleteObjectStore(sc.name);
                }

                // Primary Key
                const key = sc.key ? sc.key : "++dbid";
                //const keyPath = key.replace("++", "");
                //const autoIncrement = key.startsWith("++");
                const autoIncrement = key.startsWith("++");
                const keyPath = autoIncrement ? key.substring(2) : key;
                const objectStore = db.createObjectStore(sc.name, {
                    keyPath: keyPath,
                    autoIncrement: autoIncrement
                });

                // Foreign Keys
                if (sc.indexes) {
                    for (let ii = 0; ii < sc.indexes.length; ii++) {
                        const property = sc.indexes[ii];
                        const unique = property.startsWith("u_");
                        const propertyName = unique ? property.substring(2) : property;
                        //const unique = property.endsWith("*");
                        //const propertyName = property.replace("*", "");
                        objectStore.createIndex(propertyName, propertyName, {
                            unique: unique,
                        });
                    }
                }

            }
        };

        openRequest.onsuccess = (event: Event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            callback.call(context ?? this, db, true, null);
        };

        openRequest.onerror = (event: Event) => {
            callback.call(context ?? this, null, false, new Error("Error initializing database."));
        };
    }

    public add(
        storeName: string,
        items: IEntity | IEntity[],
        callback?: (entities: IEntity[], ok?: boolean, error?: Error) => void,
        context?: any
    ) {
        this.openDB(
            (db) => {
                const tx = db.transaction(storeName, "readwrite");
                const store = tx.objectStore(storeName);

                const itemsArray = Array.isArray(items) ? items : [items];
                const addedIds: string[] = [];
                itemsArray.forEach((item) => {
                    const request = store.add(item);
                    request.onsuccess = (event: Event) => {
                        addedIds.push(request.result as string);
                    };
                });

                tx.oncomplete = (event: Event) => {
                    itemsArray.forEach((item, index) => {
                        item.uId = addedIds[index];
                    });
                    if (callback) callback.call(context ?? this, itemsArray, true, null);
                };

                tx.onerror = (event: Event) => {
                    if (callback) callback.call(context ?? this, null, false, new Error("Error adding items."));
                };
            },
        );
    }

    public get(
        storeName: string,
        ids: number | number[],
        callback?: (entities: IEntity[], ok?: boolean, error?: Error) => void,
        context?: any
    ) {
        this.openDB(
            (db) => {
                const tx = db.transaction(storeName, "readonly");
                const store = tx.objectStore(storeName);

                const idsArray = Array.isArray(ids) ? ids : [ids];
                const items: IEntity[] = [];
                let completedRequests = 0;

                idsArray.forEach((id) => {
                    const request = store.get(id);
                    request.onsuccess = (event: Event) => {
                        const item = request.result;
                        if (item) {
                            items.push(item);
                        }
                        completedRequests++;

                        if (completedRequests === idsArray.length) {
                            if (callback) callback.call(context ?? this, items, true, null);
                        }
                    };
                });

                tx.onerror = (event: Event) => {
                    if (callback) callback.call(context ?? this, null, false, new Error("Error retrieving items."));
                };
            }
        );
    }

    public getAll(
        storeName: string,
        callback?: (entities: IEntity[], ok?: boolean, error?: Error) => void,
        context?: any
    ) {
        this.openDB(
            (db) => {
                const tx = db.transaction(storeName, "readonly");
                const store = tx.objectStore(storeName);
                const request = store.getAll();

                request.onerror = () => {
                    if (callback) callback.call(context ?? this, null, false, new Error("Error getting all items."));
                };

                request.onsuccess = () => {
                    if (callback) callback.call(context ?? this, request.result as IEntity[], true, null);
                };
            }
        );
    }

    public update(
        storeName: string,
        items: IEntity | IEntity[],
        callback?: (entities: IEntity[], ok?: boolean, error?: Error) => void,
        context?: any
    ) {
        this.openDB(
            (db) => {
                const tx = db.transaction(storeName, "readwrite");
                const store = tx.objectStore(storeName);

                const itemsArray = Array.isArray(items) ? items : [items];
                let completedRequests = 0;

                itemsArray.forEach((item) => {
                    const request = store.put(item);
                    request.onsuccess = (event: Event) => {
                        completedRequests++;

                        if (completedRequests === itemsArray.length) {
                            if (callback) callback.call(context ?? this, itemsArray, true, null);
                        }
                    };
                });

                tx.onerror = (event: Event) => {
                    if (callback) callback.call(context ?? this, null, false, new Error("Error adding items."));
                };
            }
        );
    }

    public delete(
        storeName: string,
        ids: number | number[],
        callback?: (ok?: boolean, error?: Error) => void,
        context?: any
    ) {
        this.openDB(
            (db) => {
                const tx = db.transaction(storeName, "readwrite");
                const store = tx.objectStore(storeName);

                const idsArray = Array.isArray(ids) ? ids : [ids];
                let completedRequests = 0;

                idsArray.forEach((id) => {
                    const request = store.delete(id);
                    request.onsuccess = (event: Event) => {
                        completedRequests++;
                        if (completedRequests === idsArray.length) {
                            if (callback) callback.call(context ?? this, true, null);
                        }
                    };
                });

                tx.onerror = (event: Event) => {
                    if (callback) callback.call(context ?? this, false, new Error("Error retrieving items."));
                };
            }
        );
    }


    public query(
        storeName: string,
        queryString: string,
        callback?: (entities: IEntity[], ok?: boolean, error?: Error) => void,
        context?: any
    ) {
        const queryConditions = queryString.split(/\s*&&\s*/);
        const queries: Query[] = [];

        for (const condition of queryConditions) {
            //const queryParts = condition.match(/([a-zA-Z]+)([<>=!]+)(\d+|[a-zA-Z]+)/);
            const queryParts = condition.match(/([a-zA-Z]+)\s*([<>=!]+)\s*(\d+|'[^']*'|"[^"]*")/);

            if (!queryParts || queryParts.length !== 4) {
                if (callback) callback.call(context ?? this, false, new Error("Invalid query string format."));
                return;
            }

            const value = isNaN(parseInt(queryParts[3], 10))
                ? queryParts[3].replace(/['"]/g, "")
                : parseInt(queryParts[3], 10);

            queries.push({
                property: queryParts[1],
                operator: queryParts[2] as QueryOperator,
                value: value,
            });
        }

        this.openDB(
            (db) => {
                const objectStore = db.transaction(storeName).objectStore(storeName);
                const results: IEntity[] = [];

                objectStore.openCursor().onsuccess = (event: Event) => {
                    const cursor = (event.target as IDBRequest).result as IDBCursorWithValue;

                    if (cursor) {
                        const item = cursor.value;

                        if (queries.every((query) => this.evaluateQuery(item, query))) {
                            results.push(item);
                        }

                        cursor.continue();
                    } else {
                        if (callback) callback.call(context ?? this, results, true, null);
                    }
                };
            }
        );
    }

    private evaluateQuery(item: IEntity, query: Query): boolean {
        const itemValue = item[query.property];

        switch (query.operator) {
            case "<":
                return itemValue < query.value;
            case "<=":
                return itemValue <= query.value;
            case ">":
                return itemValue > query.value;
            case ">=":
                return itemValue >= query.value;
            case "==":
                return itemValue == query.value;
            case "!=":
                return itemValue != query.value;
            default:
                return false;
        }
    }
}


export class LocalStorage {
    getItem(key: string): string {
        return window.localStorage.getItem(key);
    }

    setItem(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }
}